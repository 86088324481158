import { Row, Col, Space, Steps } from "antd";
import React from "react";
import "./benefits.css";

const { Step } = Steps;

export default function Hero() {
  let gutter = getComputedStyle(document.documentElement).getPropertyValue("--main-gutter");
  gutter = parseInt(gutter.replace("px", ""));

  return (
    <div className="benefits">
      <h3 className="section-title">Benefits</h3>
      <Row gutter={[0, gutter / 2]} className="section border">
        <Col span={24}>
          <div>
            <h3>Hi-Res Download</h3>
            <p>
              Using the tool above 👆, as long as you are the owner you can download a high-resolution version of the
              image.
            </p>
            <br />
            <h3>Early Access</h3>
            <p>Owners of a Genesis Noumena nft will get priority access to future collections.</p>
            <br />
            <h3>Community</h3>
            <p>
              Web3 provides us a unique opportunity to redefine the way we work and creates us the freedom to choose how
              we invest our time, that's why I've been investing time to help kickstart projects within our small
              community, as others have also been helping me.
            </p>
            <br />
            {/* <br />
            It's about more than just making a quick buck, but investing in one another and sharing success. The way
            Web3 should be. Come get involved! */}
            <h3>Charity</h3>
            <p>
              It almost goes without saying, but there will also be a charitable contribution, for a project that sells
              out we could donate 20% revenue, but the recipient charities are something we can decide on as a
              community.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
