import { PageHeader, Button, Menu } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-scroll";
import ReactGA from "react-ga";
import { TwitterOutlined } from "@ant-design/icons";
import { ReactComponent as DiscordIcon } from "../../../assets/icons/discord.svg";
import { ReactComponent as OpenseaIcon } from "../../../assets/icons/opensea.svg";
import { ReactComponent as EtherscanIcon } from "../../../assets/icons/etherscan.svg";
import "./header.css";

export default function Header(props) {
  return (
    <div className="header">
      <div className="left">
        <h1>Noumena</h1>
      </div>
      <div
        className={`mobile-menu ${props.menuOpen && "open"}`}
        onClick={() => {
          props.setMenuOpen(!props.menuOpen);
        }}
      >
        <div className="line top"></div>
        <div className="line middle-1"></div>
        <div className="line middle-2"></div>
        <div className="line bottom"></div>
      </div>
      <div className="right">
        <div className="menu">
          {props.isOpen && (
            <Link
              onClick={() => {
                ReactGA.event({
                  category: "nav",
                  action: "mint",
                });
              }}
              activeClass="active"
              className="item"
              to="minter"
              spy={true}
              smooth={true}
              duration={500}
              offset={-100}
            >
              mint
            </Link>
          )}

          <Link
            onClick={() => {
              ReactGA.event({
                category: "nav",
                action: "how",
              });
            }}
            activeClass="active"
            className="item"
            to="how-to"
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
          >
            how &amp; why
          </Link>

          <Link
            onClick={() => {
              ReactGA.event({
                category: "nav",
                action: "what",
              });
            }}
            activeClass="active"
            className="item"
            to="what"
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
          >
            what?
          </Link>

          <Link
            onClick={() => {
              ReactGA.event({
                category: "nav",
                action: "next",
              });
            }}
            activeClass="active"
            className="item"
            to="next"
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
          >
            next
          </Link>

          <a className="item" target="_blank" href="https://twitter.com/noumena_nft">
            <TwitterOutlined />
          </a>

          <a className="item" target="_blank" href="https://discord.gg/uMrvMEbY4U" style={{ marginTop: "4px" }}>
            <DiscordIcon />
          </a>

          <a
            className="item"
            target="_blank"
            href="https://opensea.io/collection/noumena-ai-floral"
            style={{ marginTop: "4px" }}
          >
            <OpenseaIcon />
          </a>

          <a
            className="item"
            target="_blank"
            href="https://etherscan.io/address/0xfa8467b1d5940bef0f7814827b8d548d38794677#contracts"
            style={{ marginTop: "4px" }}
          >
            <EtherscanIcon />
          </a>

          {props.isConnected && (
            <div
              className="item logout-btn"
              onClick={() => {
                ReactGA.event({
                  category: "wallet",
                  action: "disconnect",
                });
                props.logout();
              }}
            >
              logout {props.address.substr(0, 6)}...
            </div>
          )}

          {!props.isConnected && (
            <div
              className="item connect-btn"
              onClick={() => {
                ReactGA.event({
                  category: "wallet",
                  action: "connect",
                });
                props.connectWallet();
              }}
            >
              connect wallet
            </div>
          )}
        </div>
      </div>
      {/* {!!props.totalMints && props.totalMints > 0 && props.maxSupply && (
        <div className="counter">
          {props.totalMints} of {props.maxSupply} minted
        </div>
      )} */}
    </div>
  );
}
