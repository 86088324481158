import React, { useEffect, useState, useRef } from "react";
import { useInViewport } from "react-in-viewport";
// import { Row, Col, Space, Steps } from "antd";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import "./video.css";

export default function Video(props) {
  const [playing, setPlaying] = useState(true);
  const videoRef = useRef();

  const myRef = useRef();
  const { inViewport, enterCount, leaveCount, onEnterViewport } = useInViewport(
    myRef,
    {},
    { disconnectOnLeave: false },
    props,
  );

  // useEffect(() => {
  //   if (inViewport !== playing) setPlaying(inViewport);
  // }, [inViewport]);

  useEffect(() => {
    if (playing) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [playing]);

  const playVideo = () => {
    videoRef.current.play();
    setPlaying(true);
  };

  const pauseVideo = () => {
    const isPlaying =
      videoRef.current.currentTime > 0 &&
      !videoRef.current.paused &&
      !videoRef.current.ended &&
      videoRef.current.readyState > videoRef.current.HAVE_CURRENT_DATA;

    if (isPlaying) videoRef.current.pause();
    setPlaying(false);
  };

  return (
    <div className="video" ref={myRef}>
      <video
        className="video"
        ref={videoRef}
        width="1024"
        height="1024"
        loop
        muted
        onClick={() => {
          if (props.setModalSrc) {
            // pauseVideo();
            let src = props.largeSrc || props.src;
            props.setModalSrc(src);
          }
        }}
      >
        <source src={props.src} type="video/mp4" />
      </video>
      {playing && (
        <div
          className="pause-btn"
          onClick={e => {
            e.stopPropagation();
            pauseVideo();
          }}
        >
          <PauseCircleOutlined />
        </div>
      )}
      {!playing && (
        <div
          className="pause-btn"
          onClick={e => {
            e.stopPropagation();
            playVideo();
          }}
        >
          <PlayCircleOutlined />
        </div>
      )}
    </div>
  );
}
