import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Input, Tooltip, Button } from "antd";
import { ForwardOutlined, LoadingOutlined, SmileOutlined, WalletOutlined } from "@ant-design/icons";
import { ReactComponent as RandomIcon } from "../../../assets/icons/random.svg";
import { NFTStorage } from "nft.storage";
import ReactGA from "react-ga";
// import { Transactor } from "../../../helpers";
// import { useContractLoader } from "../../../hooks";
// import { useContractLoader } from "eth-hooks";
// import { useContractReader } from "eth-hooks";
import Sentencer from "../../../helpers/nameGen";
import "./minter.css";
import { NFT_STORAGE_KEY, API_URL, CHAIN } from "../../../constants";

const SHA256 = require("crypto-js/sha256");
const axios = require("axios").default;

const { ethers } = require("ethers");

// const countdownLength = 10;

async function mintNFT({
  hash,
  address,
  tx,
  contract,
  gasPrice,
  setStatus,
  setMintSuccessful,
  setIsMinting,
  setName,
  setGeneratedImageName,
  isOwner,
  image,
  name,
  description,
  setNftNumber,
}) {
  // First we use the nft.storage client library to add the image and metadata to IPFS / Filecoin
  const client = new NFTStorage({ token: NFT_STORAGE_KEY });
  setStatus(`Uploading your Noumena to IPFS, please wait...`);

  ReactGA.event({
    category: "ipfs",
    action: "start",
  });

  let metadataURI = undefined;

  try {
    const metadata = await client.store({
      name,
      description,
      image,
    });

    metadataURI = metadata.url.replace(/^ipfs:\/\//, "");
    setStatus(`Upload complete! Minting your token`);

    ReactGA.event({
      category: "ipfs",
      action: "done",
    });
  } catch (err) {
    setStatus(`IPFS Error, please try again`);

    ReactGA.event({
      category: "ipfs",
      action: "error",
    });

    return false;
  }

  // the returned metadata.url has the IPFS URI we want to add.
  // our smart contract already prefixes URIs with "ipfs://", so we remove it before calling the `mintToken` function

  let mint;

  if (isOwner) {
    mint = await tx(contract.ownerMint(metadataURI, name, { gasPrice }));
  } else {
    mint = await tx(contract.publicMint(metadataURI, name, { value: ethers.utils.parseEther("0.04"), gasPrice }));
  }

  setStatus(`Almost done, please wait a moment.`);

  const _contractToken = await contract.tokenIdForName(name);
  const contractToken = _contractToken.toNumber();

  if (mint) {
    setStatus(`Minted Noumena #${contractToken}, "${name}"`);
    setNftNumber(contractToken);
    setMintSuccessful(true);
    setName("");
    setGeneratedImageName("");
    setIsMinting(false);

    axios.get(API_URL + "/minted", {
      params: {
        hash: hash,
        address: address,
        ipfs: metadataURI,
        tokenId: contractToken,
      },
    });

    ReactGA.event({
      category: "mint",
      action: "success",
    });

    return true;
  } else {
    setStatus(undefined);
    ReactGA.event({
      category: "mint",
      action: "fail",
    });
    return false;
  }
}

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const randomName = () => {
  let seed = Math.random() * 10;
  let randomName;
  if (seed <= 3.5) {
    randomName = Sentencer.make("{{ adjective }} {{ noun }}");
  } else if (seed <= 6.5) {
    randomName = Sentencer.make("{{ adjective }} {{ adjective }} {{ noun }}");
  } else if (seed <= 8.5) {
    randomName = Sentencer.make("{{ an_adjective }} {{ noun }}");
  } else if (seed <= 10) {
    randomName = Sentencer.make("{{ an_adjective }} {{ adjective }} {{ noun }}");
  }
  return randomName;
};

export default function Minter({ address, tx, contract, gasPrice, isOwner, isConnected, connectWallet, canMint }) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [serverError, setServerError] = useState(false);

  const [isMinting, setIsMinting] = useState(false);
  const [mintSuccessful, setMintSuccessful] = useState(false);
  const [tokenId, setTokenId] = useState(undefined);

  const [seconds, setSeconds] = useState(0);
  const [photo, setPhoto] = useState(undefined);
  // const firstName = randomName();
  const startingName = useRef(randomName());
  const [nftName, setName] = useState(undefined);
  const [generatedImageName, setGeneratedImageName] = useState("");
  const [nftHash, setNftHash] = useState(undefined);
  const [nftNumber, setNftNumber] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [alreadyMinted, setAlreadyMinted] = useState(false);

  const timeoutRef = useRef(null);

  const checkName = name => {
    return new Promise(resolve => {
      contract
        .tokenIdForName(name)
        .then(res => {
          const _id = res.toNumber();
          if (_id && _id > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          resolve(false);
        });
    });
  };

  const mint = async () => {
    return cancelEvent();

    setIsMinting(true);

    ReactGA.event({
      category: "mint",
      action: "start",
    });

    const blob = b64toBlob(photo, "image/jpeg");

    const mintResult = await mintNFT({
      hash: nftHash,
      address,
      tx,
      contract,
      gasPrice,
      setStatus,
      setMintSuccessful,
      setIsMinting,
      setName,
      setGeneratedImageName,
      isOwner,
      name: generatedImageName,
      image: blob,
      description: `A Noumena Floral named "${generatedImageName}"`,
      setNftNumber,
    });

    if (!mintResult) {
      setIsMinting(false);
      setStatus("Mint Cancelled");
    }
  };

  const onAlreadyMinted = () => {
    setAlreadyMinted(true);
    setStatus(`"${nftName}"" has already been minted`);
    setIsGenerating(false);
    ReactGA.event({
      category: "generate",
      action: "done",
      label: "already minted",
    });
  };

  const startPolling = (hash, refresh = false) => {
    axios
      .get(API_URL + "/get", {
        params: {
          hash: hash,
          name: nftName,
          refresh,
        },
      })
      .then(async response => {
        // handle successs

        if (response.data.minted) {
          // done: already minted
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
          onAlreadyMinted();
        } else if (response.data.generating) {
          // not done yet
          setTimeout(() => {
            startPolling(hash);
          }, 2000);
          // startPolling();
        } else if (response.data.error) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;

          console.log("error", response.data.error);
          setIsGenerating(false);
          setAlreadyMinted(true); // for error styles
          setStatus(response.data.error);
        } else {
          // done: generating
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;

          setStatus(`A Noumena Floral named "${nftName}"`);
          setPhoto(response.data.base64);
          setGeneratedImageName(nftName);
          setIsGenerating(false);
          ReactGA.event({
            category: "generate",
            action: "done",
          });
        }

        // setSeconds(countdownLength);
      })
      .catch(function (error) {
        // handle error

        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;

        console.log("error", error);
        setIsGenerating(false);
        setAlreadyMinted(true); // for error styles
        setStatus("error generating floral, please try again");
        // setSeconds(countdownLength);
      });
  };

  const cancelEvent = () => {
    alert(
      "Generation and minting are disabled for now to keep running costs down. I hope to return to this project with part 2 soon. Thank you for visiting xx",
    );
    return false;
  };

  const getPhoto = async (refresh = false, skipCheck = false) => {
    if (!skipCheck) {
      return cancelEvent();
    }

    setIsGenerating(true);
    setAlreadyMinted(false);
    setMintSuccessful(false);
    setPhoto(undefined);
    setGeneratedImageName("");
    setStatus("starting");
    setNftNumber(undefined);

    ReactGA.event({
      category: "generate",
      action: "start",
    });

    if (!skipCheck) {
      let alreadyMinted = false;

      try {
        alreadyMinted = await checkName(nftName);
      } catch (err) {
        console.log("alreadyMinted err", err);
      }

      if (alreadyMinted) {
        onAlreadyMinted();
        return false;
      }
    }

    let messages = [
      // `waking up the ai, this can take a couple of minutes`,
      // `subsequent requests should be much quicker`,
      `generating "${nftName}" Noumena`,
      `please don't refresh!`,
    ];

    let messageIndex = 0;
    let messageDelay = 8000;
    // setStatus(messages[messageIndex]);

    const nextMessage = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
      setStatus(messages[messageIndex]);

      messageIndex = messageIndex === messages.length - 1 ? 0 : messageIndex + 1;
      timeoutRef.current = setTimeout(nextMessage, messageDelay);
    };

    nextMessage();

    const hex = SHA256(nftName).toString();
    setNftHash(hex);
    // setNftName(nftName);
    startPolling(hex, refresh);
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (startingName.current && nftName) {
      startingName.current = undefined;
      getPhoto(false, true);
    }
  }, [nftName]);

  useEffect(() => {
    axios
      .get(API_URL + "/get-recommended")
      .then(async response => {
        const res = response?.data?.recommended;
        if (res && res.length > 10) {
          startingName.current = res[Math.floor(Math.random() * res.length)];
        }
        setName(startingName.current);
      })
      .catch(error => {
        console.log("recommended db error");
        setName(startingName.current);
      });
  }, []);

  let mainGutter = getComputedStyle(document.documentElement).getPropertyValue("--main-gutter");
  mainGutter = parseInt(mainGutter.replace("px", ""));

  let greenColor = getComputedStyle(document.documentElement).getPropertyValue("--main-color");

  let openseaLink = contract
    ? CHAIN === "rinkeby"
      ? `https://testnets.opensea.io/assets/${contract.address}/`
      : `https://opensea.io/assets/${contract.address}/`
    : "";

  return (
    <div className="minter">
      <h3 className="section-title">Mint</h3>
      <Row gutter={[0, mainGutter / 2]} className="section dark">
        <Col span={24}>
          <Input
            className={`big-input ${(isGenerating || seconds > 0 || isMinting) && "disabled"}`}
            placeholder="Choose a name"
            maxLength={50}
            value={nftName}
            onChange={e => {
              let name = e.target.value.replace(/[`#$%^&_|+\=;:".<>\{\}\[\]\\\/]/gi, "");
              setName(name);
            }}
            onPressEnter={e => {
              if (nftName === "") return;
              e.target.blur();
              getPhoto();
            }}
            suffix={
              // seconds > 0 && (
              //   <Progress
              //     width={50}
              //     type="circle"
              //     percent={(1 - seconds / countdownLength) * 100}
              //     format={() => `${seconds}`}
              //     style={{ marginRight: "-20px" }}
              //   />
              // )
              <Tooltip title="random" color={greenColor}>
                <Button
                  shape="circle"
                  className="random-button"
                  icon={<RandomIcon className="random-icon" />}
                  size="large"
                  onClick={() => {
                    setName(randomName());
                  }}
                />
              </Tooltip>
            }
          />
        </Col>

        <Col span={24}>
          <Row gutter={[mainGutter / 2, 0]}>
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 12, offset: 0 }}
              md={{ span: 12, offset: 0 }}
              lg={{ span: 12, offset: 0 }}
              xl={{ span: 10, offset: 0 }}
            >
              {/* {!isGenerating && (generatedImageName !== nftName || nftName.length === 0) && ( */}
              {!isGenerating && (
                <div
                  className={`button ${
                    (seconds > 0 || isMinting || nftName === "" || generatedImageName === nftName) && "disabled"
                  }`}
                  onClick={() => {
                    getPhoto();
                  }}
                >
                  <h3>
                    Generate
                    <ForwardOutlined />
                  </h3>
                </div>
              )}

              {/* {!isGenerating && photo && generatedImageName === nftName && (
                <div
                  className={`button ${(seconds > 0 || isMinting || nftName === "") && "disabled"}`}
                  onClick={() => {
                    getPhoto(true);
                  }}
                >
                  <h3>
                    Regenerate
                    <RedoOutlined />
                  </h3>
                </div>
              )} */}

              {isGenerating && (
                <div className="button disabled">
                  <h3>
                    Generating
                    <LoadingOutlined />
                  </h3>
                </div>
              )}
            </Col>
            {canMint && (
              <Col
                xs={{ span: 12, offset: 0 }}
                sm={{ span: 12, offset: 0 }}
                md={{ span: 12, offset: 0 }}
                lg={{ span: 12, offset: 0 }}
                xl={{ span: 14, offset: 0 }}
              >
                {!isConnected && (
                  <div
                    className={`button white`}
                    onClick={() => {
                      connectWallet();
                    }}
                  >
                    <h3>
                      Connect Wallet
                      <WalletOutlined style={{ marginLeft: "10px" }} />
                    </h3>
                  </div>
                )}
                {!isGenerating && photo && !mintSuccessful && isConnected && (
                  <div
                    className={`button white ${
                      (isMinting || isGenerating || !photo || generatedImageName !== nftName) && "disabled"
                    }`}
                    onClick={() => {
                      mint();
                    }}
                  >
                    <h3>Mint for .04 Ξ{isMinting && <LoadingOutlined />}</h3>
                  </div>
                )}
              </Col>
            )}
          </Row>
        </Col>
        {status && (
          <Col span={24}>
            <span className={`status ${alreadyMinted ? "minted" : ""} ${mintSuccessful ? "success" : ""}`}>
              {status}
            </span>
            <br />
            {mintSuccessful && (
              <span>
                <a href={`${openseaLink}${nftNumber}`} target="_blank" rel="noreferrer">
                  See on Opensea
                </a>
                <span style={{ color: "var(--border-color)" }}> (it may take some time to propagate)</span>
              </span>
            )}
          </Col>
        )}
        <Col span={24} className="result">
          {photo ? (
            <div className="thumb" style={{ backgroundImage: `url(data:image/jpg;base64,${photo})` }} />
          ) : (
            <div className="thumb loading" />
          )}
        </Col>
      </Row>
    </div>
  );
}
