import { Row, Col, Space } from "antd";
import React from "react";
import "./whatIsIt.css";
// import heroVideo from "../../../assets/flowers-1.mp4";
// import Video from "../Video";

const photos = [
  {
    small: require("../../../assets/photos/small/2.jpg").default,
    large: require("../../../assets/photos/large/2.jpg").default,
  },
  {
    small: require("../../../assets/photos/small/8.jpg").default,
    large: require("../../../assets/photos/large/8.jpg").default,
  },
  {
    small: require("../../../assets/photos/small/4.jpg").default,
    large: require("../../../assets/photos/large/4.jpg").default,
  },
  // {
  //   small: require("../../../assets/photos/small/7.jpg").default,
  //   large: require("../../../assets/photos/large/7.jpg").default,
  // },
  {
    small: require("../../../assets/photos/small/6.jpg").default,
    large: require("../../../assets/photos/large/6.jpg").default,
  },
  {
    small: require("../../../assets/photos/small/9.jpg").default,
    large: require("../../../assets/photos/large/9.jpg").default,
  },
  {
    small: require("../../../assets/photos/small/3.jpg").default,
    large: require("../../../assets/photos/large/3.jpg").default,
  },
  // {
  //   small: require("../../../assets/photos/small/5.jpg").default,
  //   large: require("../../../assets/photos/large/5.jpg").default,
  // },
  // {
  //   small: require("../../../assets/photos/small/1.jpg").default,
  //   large: require("../../../assets/photos/large/1.jpg").default,
  // },
];

export default function whatIsIt(props) {
  let gutter = getComputedStyle(document.documentElement).getPropertyValue("--main-gutter");
  gutter = parseInt(gutter.replace("px", ""));

  return (
    <div className="what-is-it">
      <Row align="top" gutter={gutter}>
        <Col className="intro" md={24} lg={16}>
          <h3 className="section-title">What is Noumena</h3>
          <div className="section dark">
            <p>
              Noumena was created by Sam (
              <a href="https://www.linkedin.com/in/samuelfairbairn/" target="_blank">
                LinkedIn,
              </a>{" "}
              <a href="https://www.instagram.com/samuel_fairb/" target="_blank">
                Instagram
              </a>
              ), a creative technologist from London, currently based in Shanghai. The Floral collection is a
              combination of two of his passions, traditional photography and new technology.
            </p>

            <h3>How it works</h3>
            <p>
              The AI model was trained on a dataset of carefully selected 35mm floral photography (including Sam's own
              photos 👉) taken with a selection of Contax, Yashica and Olympus cameras, with the objective of
              reproducing the aesthetics of those particular cameras.
            </p>
            <p>
              To generate a unique image your chosen name is fed through an algorithm that generates the 512 vectors
              that are required to pin-point a location within the neural network. The name you choose will always
              produce the same 512 values and so return the same image from the neural network's latent space. The
              result is then saved on IPFS and your chosen name is also saved in the smart contract ensuring it can't be
              used again.
            </p>

            <h3 className="second">Exploring a new perspective</h3>
            <p>
              The name Noumena refers to Kantian philosophy, where Immanuel Kant posited that the way we experience
              things through our senses (phenomena) and the true nature of the thing, the 'thing-in-itself', the
              noumena, are not one and the same.
            </p>
            <p>
              According to Objected-Oriented Ontology art can act like a metaphor, as a way to experience qualities of
              an 'object' that we don’t normally have access to, by seeing it from a different perspective.
            </p>
            <p>
              So by collaborating with AI we can attempt to explore the true nature of the flowers, discovering
              qualities that exist beyond those we are used to seeing everyday, and experiencing them afresh.
            </p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <div className="section images">
            <Row>
              {photos.map((f, index) => {
                return (
                  <Col
                    xs={8}
                    lg={12}
                    key={`row-1-${index}`}
                    onClick={() => {
                      props.setModalSrc(f.large);
                    }}
                  >
                    <span className="photo-thumb" style={{ backgroundImage: `url(${f.small})` }} />
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
