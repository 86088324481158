import React from "react";
import { Row, Col } from "antd";
import "./hero.css";

export default function Hero(props) {
  let gutter = getComputedStyle(document.documentElement).getPropertyValue("--main-gutter");
  gutter = parseInt(gutter.replace("px", ""));

  return (
    <div className="hero">
      <Row gutter={[gutter, gutter]} align="bottom">
        <Col span={24}>
          <div className="section border intro">
            <h1 style={{ marginTop: 0 }}>Generative art meets floral Photography</h1>
            <h3>
              Join forces with the AI to create your own personalized piece of art, saved irrevocably on the Ethereum
              blockchain.
            </h3>
            <h3>
              The name you choose becomes the seed used to identify a unique location within the neural network, which
              once minted is saved on the blockchain and eternally yours.
            </h3>
            <h3 style={{ marginBottom: 0 }}>One of the very first collaborative AI NFT projects!</h3>
            {!props.isOpen && !props.soldOut && (
              <>
                <h3>
                  Follow our{" "}
                  <a target="_blank" href="https://twitter.com/noumena_nft">
                    twitter
                  </a>{" "}
                  and{" "}
                  <a target="_blank" href="https://discord.gg/uMrvMEbY4U">
                    discord
                  </a>{" "}
                  to be the first to find out more about the upcoming drop.
                </h3>
                <div className="button-wrapper">
                  <h2 style={{ marginBottom: 0 }}>Coming Soon...</h2>
                </div>
              </>
            )}

            {props.soldOut && (
              <div className="button-wrapper">
                <h2 style={{ marginTop: "var(--main-gutter)" }}>Sold Out</h2>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
