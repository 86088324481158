import React, { useRef, useEffect } from "react";
import { LinkOutlined } from "@ant-design/icons";
import { CHAIN } from "../../../constants";
import "./nft-item.css";

export default function NFTItem(props) {
  const url = useRef();

  useEffect(() => {
    url.current =
      CHAIN === "rinkeby"
        ? `https://testnets.opensea.io/assets/${props.contractAddress}/${props.item?.tokenId}`
        : `https://opensea.io/assets/${props.contractAddress}/${props.item?.tokenId}`;
  }, [props.item, props.contractAddress]);

  return (
    <div
      className="nft-item"
      onClick={() => {
        props.setModalSrc(props.item.url);
      }}
    >
      {props.item ? (
        <div className="thumb" style={{ backgroundImage: `url(${props.item.url})` }}>
          <a href={url.current} target="_blank" className="name" onClick={e => e.stopPropagation()}>
            <span>{props.item.name}</span>
            <LinkOutlined />
          </a>
        </div>
      ) : (
        <div className="thumb loading"></div>
      )}
    </div>
  );
}
