import { PageHeader, Button, Menu } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { TwitterOutlined } from "@ant-design/icons";
import { ReactComponent as DiscordIcon } from "../../../assets/icons/discord.svg";
import { ReactComponent as OpenseaIcon } from "../../../assets/icons/opensea.svg";
import { ReactComponent as EtherscanIcon } from "../../../assets/icons/etherscan.svg";
import "./footer.css";

export default function Header(props) {
  return (
    <div className="footer">
      <h3>Noumena</h3>
      <div className="menu">
        <a className="item" target="_blank" href="https://github.com/scaffold-eth/scaffold-eth">
          made with <br className="mobile-only" />
          scaffold-eth
        </a>
        <a className="item" target="_blank" href="https://twitter.com/noumena_nft">
          <TwitterOutlined />
        </a>
        <a className="item" target="_blank" href="https://discord.gg/uMrvMEbY4U" style={{ marginTop: "4px" }}>
          <DiscordIcon />
        </a>
        <a
          className="item"
          target="_blank"
          href="https://opensea.io/collection/noumena-ai-floral"
          style={{ marginTop: "4px" }}
        >
          <OpenseaIcon />
        </a>
        <a
          className="item"
          target="_blank"
          href="https://etherscan.io/address/0xfa8467b1d5940bef0f7814827b8d548d38794677#contracts"
          style={{ marginTop: "4px" }}
        >
          <EtherscanIcon />
        </a>
      </div>
    </div>
  );
}
