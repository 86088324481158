import React, { useEffect, useState } from "react";
import { Row, Col, Space, Steps } from "antd";
import Slider from "react-slick";
import NFTItem from "../NFTItem/index";
import { API_URL } from "../../../constants";
import "./recents.css";

const axios = require("axios").default;

export default function Recents(props) {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 2,
    slidesPerRow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const [recentlyAdded, setRecentlyAdded] = useState(undefined);

  useEffect(() => {
    // get recently added images
    axios
      .get(API_URL + "/recent/")
      .then(async response => {
        setRecentlyAdded(response.data?.recentlyMinted);
      })
      .catch(function (error) {
        console.log("recent added db error");
      });
  }, []);

  return (
    <div className="recents">
      <Row>
        <Col className="mobile-title" span={24}>
          <h3 className="section-title">Recently Minted</h3>
        </Col>
        <Col span={24}>
          <Slider {...settings}>
            {/* {props.list.map((item, index) => { */}
            {[...Array(18)].map((item, index) => {
              return (
                <div className="slide" key={`recent-image-${index}`}>
                  <NFTItem
                    contractAddress={props.contractAddress}
                    item={recentlyAdded && recentlyAdded[index]}
                    setModalSrc={props.setModalSrc}
                  />
                </div>
              );
            })}
          </Slider>
        </Col>
        {/* <Col className="sideways-title" span={2}>
          <h3 className="section-title">Recently Minted</h3>
        </Col> */}
      </Row>
    </div>
  );
}
