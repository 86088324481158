// import WalletConnectProvider from "@walletconnect/web3-provider";
// import WalletLink from "walletlink";
import { Alert, Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Web3Modal from "web3modal";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Header,
  Hero,
  Banner,
  Minter,
  Download,
  Benefits,
  WhatIsIt,
  Favourites,
  Next,
  Footer,
  Modal,
  MobileNav,
  Recents,
  Video,
} from "./components/noumena";
import { NETWORKS, DEFAULT_CONTRACT_NAME, DEBUG, API_URL, CHAIN, ANALYTICS_ID, STORE_OPEN } from "./constants";
import { Transactor } from "./helpers";
import { useContractReader, useGasPrice } from "eth-hooks";
import { useContractLoader, useUserProviderAndSigner } from "./ethHooks";
// import { useEventListener } from "eth-hooks/events/useEventListener";
// import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
// import Hints from "./Hints";
// import { ExampleUI, Hints, Subgraph } from "./views";

// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import externalContracts from "./contracts/external_contracts";

import heroVideo from "./assets/flowers-1.mp4";
// const heroVideo = require("./assets/flowers-2-l.mp4").default;

// import { useContractConfig } from "./hooks";
// import Portis from "@portis/web3";
// import Fortmatic from "fortmatic";
// import Authereum from "authereum";

import { Element } from "react-scroll";

import ReactGA from "react-ga";

const { ethers } = require("ethers");
const axios = require("axios").default;

/// 📡 What chain are your contracts deployed to?
// const targetNetwork = NETWORKS.localhost;
// const targetNetwork = NETWORKS.rinkeby;

const targetNetwork = NETWORKS[CHAIN];

if (DEBUG) {
  console.log("REACT_APP environment variables");
  console.log("REACT_APP_CONTRACT_NAME", process.env.REACT_APP_CONTRACT_NAME);
  console.log("REACT_APP_INFURA_ID", process.env.REACT_APP_INFURA_ID);
  console.log("REACT_APP_DEBUG", process.env.REACT_APP_DEBUG);
  console.log("REACT_APP_ANALYTICS_ID", process.env.REACT_APP_ANALYTICS_ID);
  console.log("REACT_APP_CHAIN", process.env.REACT_APP_CHAIN);
  console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);
  console.log("\n");
}

// 🛰 providers
// const mainnetProvider = getDefaultProvider("mainnet", { infura: INFURA_ID, etherscan: ETHERSCAN_KEY, quorum: 1 });
// const mainnetProvider = new InfuraProvider("mainnet",INFURA_ID);
//
// attempt to connect to our own scaffold eth rpc and if that fails fall back to infura...
// Using StaticJsonRpcProvider as the chainId won't change see https://github.com/ethers-io/ethers.js/issues/901
// const scaffoldEthProvider = navigator.onLine
//   ? new ethers.providers.StaticJsonRpcProvider("https://rpc.scaffoldeth.io:48544")
//   : null;
// const poktMainnetProvider = navigator.onLine
//   ? new ethers.providers.StaticJsonRpcProvider(
//       "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
//     )
//   : null;

// const mainnetInfura = navigator.onLine
//   ? new ethers.providers.StaticJsonRpcProvider("https://mainnet.infura.io/v3/" + INFURA_ID)
//   : null;

// ( ⚠️ Getting "failed to meet quorum" errors? Check your INFURA_ID
// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;
// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
const localProvider = new ethers.providers.StaticJsonRpcProvider(localProviderUrlFromEnv);

// 🔭 block explorer URL
// const blockExplorer = targetNetwork.blockExplorer;

// Coinbase walletLink init
// const walletLink = new WalletLink({
//   appName: "coinbase",
// });

/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  network: "mainnet", // Optional. If using WalletConnect on xDai, change network to "xdai" and add RPC info below for xDai chain.
  cacheProvider: true, // optional
  theme: "light", // optional. Change to "dark" for a dark theme.
  providerOptions: {
    // walletconnect: {
    //   package: WalletConnectProvider, // required
    //   options: {
    //     bridge: "https://polygon.bridge.walletconnect.org",
    //     infuraId: INFURA_ID,
    //     rpc: {
    //       1: `https://mainnet.infura.io/v3/${INFURA_ID}`, // mainnet // For more WalletConnect providers: https://docs.walletconnect.org/quick-start/dapps/web3-provider#required
    //       42: `https://kovan.infura.io/v3/${INFURA_ID}`,
    //       100: "https://dai.poa.network", // xDai
    //     },
    //   },
    // },
  },
});

function App(props) {
  // const mainnetProvider =
  //   poktMainnetProvider && poktMainnetProvider._isProvider
  //     ? poktMainnetProvider
  //     : scaffoldEthProvider && scaffoldEthProvider._network
  //     ? scaffoldEthProvider
  //     : mainnetInfura;

  // const mainnetProvider = mainnetInfura;

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [alwaysOn, setAlwaysOn] = useState(undefined);

  useEffect(() => {
    ReactGA.initialize(ANALYTICS_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const checkIsAlwaysOn = () => {
    axios
      .get(API_URL + "/is-always-on/")
      .then(async response => {
        if (DEBUG) console.log("response", response.data);
        setAlwaysOn(response.data.keepAlive);
      })
      .catch(function (error) {
        console.log("/is-always-on/ error");
      });
  };

  const changeIsAlwaysOn = onOff => {
    axios
      .get(API_URL + "/set-always-on/", {
        params: {
          isOn: onOff,
        },
      })
      .then(async response => {
        if (DEBUG) console.log("change response", response.data);
        setAlwaysOn(response.data.keepAlive);
      })
      .catch(function (error) {
        console.log("/set-always-on/ error");
      });
  };

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "average", 5 * 60 * 1000);
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider);
  const userSigner = userProviderAndSigner.signer;
  if (DEBUG) console.log("userProviderAndSigner", userProviderAndSigner);

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);

  // Faucet Tx can be used to send funds from the faucet
  // const faucetTx = Transactor(localProvider, gasPrice);

  // const contractConfig = useContractConfig();
  const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  const contract = readContracts && readContracts[DEFAULT_CONTRACT_NAME];
  if (DEBUG && contract) console.log("contract", contract);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);

  const writeableContract = writeContracts && writeContracts[DEFAULT_CONTRACT_NAME];
  if (DEBUG && writeableContract) console.log("writeableContract", writeableContract);

  // keep track of a variable from the contract in the local React state:
  // const purpose = useContractReader(readContracts, "YourContract", "purpose");

  // 📟 Listen for broadcast events
  // const setPurposeEvents = useEventListener(readContracts, "YourContract", "SetPurpose", localProvider, 1);

  // const owner = useContractReader(readContracts, DEFAULT_CONTRACT_NAME, "owner");
  const owner = "0x6be28ea8e5CFEF9A6631e6ddB3Ef35913a558e76";
  if (DEBUG) console.log("owner:", owner);
  if (DEBUG) console.log("address:", address);

  const pollMinutes = 100;
  const _totalMints = useContractReader(
    readContracts,
    DEFAULT_CONTRACT_NAME,
    "tokenSupply",
    [],
    pollMinutes * 60 * 1000,
  );
  const totalMints = _totalMints ? _totalMints.toNumber() : 0;

  // const _contractMax = useContractReader(readContracts, DEFAULT_CONTRACT_NAME, "maxSupply", [], 60 * 60 * 1000);
  // const contractMax = _contractMax ? _contractMax.toNumber() : 1001;
  // if (DEBUG) console.log("maxSupply:", contractMax);
  // const storeOpen = totalMints < contractMax - 1;

  const maxSupply = 1000;
  const soldOut = totalMints >= maxSupply;

  if (DEBUG) console.log("soldOut", soldOut);

  // const _balance = useContractReader(
  //   readContracts,
  //   DEFAULT_CONTRACT_NAME,
  //   "balanceOf",
  //   [address],
  //   pollMinutes * 60 * 1000,
  // );
  // let balance = _balance ? _balance.toNumber() : 0;
  // if (DEBUG) console.log("balance:", balance);

  const isOwner = address && owner && address === owner;
  if (DEBUG) console.log("isOwner", isOwner);

  const canMint = (STORE_OPEN || isOwner) && !soldOut;

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      if (DEBUG) console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      if (DEBUG) console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      if (DEBUG) console.log(code, reason);
      logoutOfWeb3Modal();
    });
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  // let faucetHint = "";
  // const faucetAvailable = localProvider && localProvider.connection && targetNetwork.name.indexOf("local") !== -1;

  // const [faucetClicked, setFaucetClicked] = useState(false);
  // if (
  //   !faucetClicked &&
  //   localProvider &&
  //   localProvider._network &&
  //   localProvider._network.chainId === 31337
  //   // yourLocalBalance &&
  //   // ethers.utils.formatEther(yourLocalBalance) <= 0
  // ) {
  //   faucetHint = (
  //     <div style={{ padding: 16 }}>
  //       <Button
  //         type="primary"
  //         onClick={() => {
  //           faucetTx({
  //             to: address,
  //             value: ethers.utils.parseEther("0.1"),
  //           });
  //           setFaucetClicked(true);
  //         }}
  //       >
  //         💰 Grab funds from the faucet ⛽️
  //       </Button>
  //     </div>
  //   );
  // }

  const flowers = [
    require("./assets/flowers-resized/1.jpg").default,
    require("./assets/flowers-resized/2.jpg").default,
    require("./assets/flowers-resized/3.jpg").default,
    require("./assets/flowers-resized/4.jpg").default,
    require("./assets/flowers-resized/7.jpg").default,
    // require("./assets/flowers-resized/5.jpg").default,
    // require("./assets/flowers-resized/6.jpg").default,
    // require("./assets/flowers-resized/11.jpg").default,
    // require("./assets/flowers-resized/9.jpg").default,
    require("./assets/flowers-resized/10.jpg").default,
    // require("./assets/flowers-resized/12.jpg").default,
    // require("./assets/flowers-resized/8.jpg").default,
  ];

  const [modalSrc, setModalSrc] = useState(undefined);
  const [menuOpen, setMenuOpen] = useState(false);

  let gutter = getComputedStyle(document.documentElement).getPropertyValue("--main-gutter");
  gutter = parseInt(gutter.replace("px", ""));

  return (
    <div className="App">
      {CHAIN === "rinkeby" && selectedChainId !== 4 && (
        <Alert message="Make sure Rinkeby Test Network is selected in MetaMask" banner />
      )}
      {CHAIN === "mainnet" && selectedChainId !== 1 && (
        <Alert message="Make sure the Ethereum Mainnet network is selected in MetaMask" banner />
      )}
      <Header
        isOpen={canMint}
        isConnected={!!injectedProvider}
        address={address}
        logout={logoutOfWeb3Modal}
        connectWallet={loadWeb3Modal}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}
        // totalMints={totalMints}
        maxSupply={maxSupply}
      />

      <div className="content">
        <Row gutter={[gutter, gutter]} className="page-section">
          <Col md={24} lg={12} xl={14}>
            <Row gutter={[gutter, gutter]}>
              <Col span={24}>
                <Hero
                  isOpen={canMint}
                  // totalMints={totalMints}
                  soldOut={soldOut}
                  isConnected={!!injectedProvider}
                  connectWallet={loadWeb3Modal}
                  setModalSrc={setModalSrc}
                />
              </Col>
              <Col span={24}>
                {/* {recentlyAdded && contract && (
                  )} */}
                <Recents contractAddress={contract?.address} setModalSrc={setModalSrc} />
              </Col>
            </Row>
          </Col>
          <Col md={24} lg={12} xl={10}>
            <Row gutter={[gutter, gutter]}>
              <Col span={24}>
                <Element name="minter">
                  <Minter
                    tx={tx}
                    contract={writeableContract}
                    isOwner={isOwner}
                    address={address}
                    isConnected={!!injectedProvider}
                    connectWallet={loadWeb3Modal}
                    canMint={canMint}
                  />
                </Element>
              </Col>
              <Col span={24}>
                <Element name="download">
                  <Download userSigner={userSigner} isConnected={!!injectedProvider} connectWallet={loadWeb3Modal} />
                </Element>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[gutter, gutter]} className="page-section">
          <Col md={24} lg={10}>
            <Row>
              <Col>
                <Element name="how-to">
                  <Banner />
                </Element>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                className="video"
                onClick={() => {
                  setModalSrc(heroVideo);
                }}
              >
                <Video style={{ width: "100%" }} src={heroVideo} />
              </Col>
            </Row>
          </Col>
          <Col md={24} lg={14}>
            <Row className="flowers-grid">
              {flowers.map((f, index) => {
                return (
                  <Col
                    span={8}
                    key={`row-1-${index}`}
                    onClick={() => {
                      setModalSrc(f);
                    }}
                  >
                    <img className="thumb" alt={`thumbnail ${index}`} src={f} />
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col>
                <Element name="benefits">
                  <Benefits />
                </Element>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={[gutter, gutter]} className="page-section">
          <Col span={24}>
            <Element name="what">
              <WhatIsIt setModalSrc={setModalSrc} />
            </Element>
          </Col>
        </Row>

        <Row gutter={[gutter, gutter]}>
          <Col span={24}>
            <Element name="next">
              <Next setModalSrc={setModalSrc} />
            </Element>
          </Col>
        </Row>
      </div>
      <Footer />
      <Modal src={modalSrc} setModalSrc={setModalSrc} />
      <MobileNav
        isOpen={canMint}
        isConnected={!!injectedProvider}
        address={address}
        logout={logoutOfWeb3Modal}
        connectWallet={loadWeb3Modal}
        setMenuOpen={setMenuOpen}
        menuOpen={menuOpen}
      />
    </div>
  );
}

export default App;
