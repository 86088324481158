import { Row, Col, Space, Steps } from "antd";
import React from "react";
import "./banner.css";

const { Step } = Steps;

export default function Hero() {
  return (
    <div className="banner">
      <h3 className="section-title">How to mint</h3>
      {/* <Row>
        <Col span={24}>
          <Steps current={0}>
            <Step title="Connect" description="Connect your metamask wallet" />
            <Step title="Generate" description="Generate a flower, if you don't like it you can try again" />
            <Step title="Mint" description="Save your artwork on the blockchain" />
          </Steps>
        </Col>
      </Row> */}
      {/* <h2>How it works</h2> */}
      <Row gutter={[0, 10]} className="section dark">
        <Col span={24}>
          <div className="step">
            <span className="number">1</span>
            <div className="copy">
              <h3>Name your Noumena</h3>
              <p>Choose a name to generate a flower, experiment until you find one you like.</p>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="step">
            <span className="number">2</span>
            <div className="copy">
              <h3>Connect</h3>
              <p>Connect with your MetaMask Wallet.</p>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="step">
            <span className="number">3</span>
            <div className="copy">
              <h3>Mint!</h3>
              <p>
                Once you're happy with your creation, mint! We'll save your Noumena to IPFS before you sign the
                transaction.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
