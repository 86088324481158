import React, { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import Video from "../Video";
import "./modal.css";

export default function Hero(props) {
  let gutter = getComputedStyle(document.documentElement).getPropertyValue("--main-gutter");
  gutter = parseInt(gutter.replace("px", ""));

  const [contentStyle, setContentStyle] = useState({});

  // const size = useRef({ w: window.innerWidth, h: window.innerHeight });

  const setSize = () => {
    let _contentStyle =
      window.innerWidth > window.innerHeight ? { width: "80vh", height: "80vh" } : { width: "80vw", height: "80vw" };
    setContentStyle(_contentStyle);
  };

  useEffect(() => {
    setSize();
    window.addEventListener("resize", setSize);
  }, []);

  // const contentStyle =
  //   size.current.w > size.current.h ? { width: "80vh", height: "80vh" } : { width: "80vw", height: "80vw" };

  if (!props.src) return null;

  console.log("props.src", props.src);

  const isVideo = props.src.substr(-4) === ".mp4";

  return (
    <div className="modal">
      <div
        className="background"
        onClick={() => {
          props.setModalSrc(undefined);
        }}
      ></div>
      <div className="content" style={contentStyle}>
        <div
          className="close-btn"
          onClick={() => {
            props.setModalSrc(undefined);
          }}
        >
          <CloseCircleOutlined />
        </div>
        <div className="image-wrapper">
          {isVideo ? <Video src={props.src} /> : <span style={{ backgroundImage: `url(${props.src})` }} />}
        </div>
      </div>
    </div>
  );
}
