import { Row, Col, Space } from "antd";
import React from "react";
import "./next.css";
import Video from "../Video";
// import flowerLineS from "../../../assets/flowers-3-s.mp4";
// import flowerLineL from "../../../assets/flowers-3-l.mp4";
// import flower2 from "../../../assets/flowers-2.mp4";
// import flower2S from "../../../assets/iris-2-s.mp4";
// import flower2L from "../../../assets/iris-2-l.mp4";

const grid = [
  {
    name: "Flower Line",
    video: true,
    file: require("../../../assets/flowers-3-s.mp4").default,
    largeFile: require("../../../assets/flowers-3-l.mp4").default,
  },
  {
    name: "A Daisy",
    file: require("../../../assets/species-stills/small/daisy-1.jpg").default,
  },
  {
    name: "Cherry Blossom",
    file: require("../../../assets/species-stills/small/blossom-1.jpg").default,
  },
  {
    name: "Iris",
    file: require("../../../assets/species-stills/small/iris-3.jpg").default,
  },
  {
    name: "A Rose",
    file: require("../../../assets/species-stills/small/rose-2.jpg").default,
  },
  {
    name: "A Tulip",
    video: true,
    file: require("../../../assets/tulip-1-s.mp4").default,
    largeFile: require("../../../assets/tulip-1-l.mp4").default,
  },
  // {
  //   name: "Cherry Blossom",
  //   file: require("../../../assets/species-stills/small/blossom-2.jpg").default,
  // },
  // {
  //   name: "Tulip",
  //   file: require("../../../assets/species-stills/small/tulip-2.jpg").default,
  // },
  // {
  //   name: "Iris",
  //   file: require("../../../assets/species-stills/small/iris-5.jpg").default,
  // },
  // {
  //   name: "Cherry Blossom",
  //   video: true,
  //   file: require("../../../assets/cherry-blossom-2-s.mp4").default,
  //   largeFile: require("../../../assets/cherry-blossom-2-l.mp4").default,
  // },
  // {
  //   name: "Daisy",
  //   video: true,
  //   file: require("../../../assets/daisy-1-s.mp4").default,
  //   largeFile: require("../../../assets/daisy-1-l.mp4").default,
  // },
  // {
  //   name: "Rose",
  //   file: require("../../../assets/species-stills/small/rose-3.jpg").default,
  // },
  // {
  //   name: "Tulip",
  //   file: require("../../../assets/species-stills/small/tulip-1.jpg").default,
  // },
];

export default function Next(props) {
  let gutter = getComputedStyle(document.documentElement).getPropertyValue("--main-gutter");
  gutter = parseInt(gutter.replace("px", ""));

  return (
    <div className="next">
      <Row align="top" gutter={[gutter, gutter]}>
        <Col className="details" sm={24} md={24} lg={8}>
          <h3 className="section-title">What's Next</h3>
          <Row align="top" gutter={[gutter / 2, gutter / 2]}>
            <Col xs={24} sm={22} md={22} lg={24}>
              <div className="section border" style={{ paddingBottom: "var(--main-gutter)" }}>
                <h3>Upcoming Collections</h3>
                <p>
                  The next collection will be animated Noumena, more ambitious, technically challenging and diverse than
                  the genesis collection, and will including traits and rarity for increased collectability.
                </p>

                <h3 style={{ marginTop: "var(--main-gutter)" }}>And Beyond</h3>
                <p style={{ marginBottom: "0" }}>
                  We're also looking forward to building with and investing into our community, whether that involves
                  hosting new artists here on Noumena, or building something brand new, we can decide together.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="thumbs" sm={24} md={24} lg={16}>
          <Row align="top" className="species">
            <Col span={24}>
              <Row>
                {grid.map((item, index) => {
                  return (
                    <Col xs={12} sm={8} md={8} key={`grid-item-${index}`}>
                      {item.video ? (
                        <Video className="video" src={item.file} setModalSrc={props.setModalSrc} />
                      ) : (
                        <img
                          src={item.file}
                          alt={item.name}
                          className="thumb"
                          onClick={() => {
                            if (props.setModalSrc) {
                              let file = item.video ? item.largeFile : item.file;
                              props.setModalSrc(file);
                            }
                          }}
                        />
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
