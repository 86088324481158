import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button, InputNumber, Input, Popover } from "antd";
import { LoadingOutlined, EditOutlined, DownloadOutlined } from "@ant-design/icons";
import { DEBUG, API_URL } from "../../../constants";
const axios = require("axios").default;
import tokenIdImage from "../../../assets/tokenId.jpg";
import "./download.css";

export default function Download({ userSigner, isConnected, connectWallet }) {
  const [downloadTokenID, setDownloadTokenID] = useState();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(undefined);
  const [downloadUrl, setDownloadUrl] = useState(undefined);

  const downloadTimer = useRef();

  const clearTimer = () => {
    if (downloadTimer.current) {
      clearTimeout(downloadTimer.current);
      downloadTimer.current = null;
    }
  };

  useEffect(() => {
    if (downloadUrl) {
      downloadTimer.current = setTimeout(() => {
        clearTimer();
        setDownloadUrl(undefined);
      }, 1000 * 60 * 5);
    } else {
      clearTimer();
    }
  }, [downloadUrl]);

  useEffect(() => {
    setDownloadUrl(undefined);
  }, [downloadTokenID]);

  const download = (url, name) => {
    const a = document.createElement("a");
    a.href = url;
    a.style = "display: none";

    if (name && name.length) a.download = name;
    document.body.appendChild(a);
    a.click();
  };

  const downloadToken = (signature, tokenId) => {
    setFetching(true);
    setError(undefined);
    setDownloadUrl(undefined);
    axios
      .get(API_URL + "/download/", {
        params: {
          signature,
          tokenId,
        },
      })
      .then(async response => {
        if (DEBUG) console.log(">> download response", response);

        if (response.data.error) {
          setError(response.data.error);
        } else if (response.data) {
          setDownloadUrl(response.data);
          download(response.data, `${downloadTokenID}-hires.zip`);
        } else {
          setError("Unknown error, please try again.");
        }

        setFetching(false);
      })
      .catch(function (error) {
        if (DEBUG) console.log("/download/ error", error);
        setFetching(false);
      });
  };

  let mainGutter = getComputedStyle(document.documentElement).getPropertyValue("--main-gutter");
  mainGutter = parseInt(mainGutter.replace("px", ""));
  let greenColor = getComputedStyle(document.documentElement).getPropertyValue("--main-color");

  return (
    <div className="download">
      <h3 className="section-title">Hi-Res Download</h3>
      <Row gutter={[0, 0]} className="section dark">
        {isConnected ? (
          <Col span={24}>
            <Row gutter={[mainGutter / 2, mainGutter / 2]}>
              <Col span={24}>
                <p>
                  Enter the&nbsp;
                  <Popover
                    content={<img src={tokenIdImage} style={{ width: "400px" }} />}
                    title="Title"
                    trigger="hover"
                  >
                    token id
                  </Popover>
                  &nbsp;of your Noumena and sign a message so we can verify you are the owner, your download will start
                  automatically.
                </p>
              </Col>
              <Col span={14}>
                <Input
                  className={`big-input ${fetching && "disabled"}`}
                  placeholder="Token ID"
                  maxLength={4}
                  value={downloadTokenID}
                  onChange={e => {
                    setDownloadTokenID(e.target.value);
                  }}
                  type="number"
                  disabled={fetching}
                />
              </Col>

              <Col span={10}>
                {downloadUrl ? (
                  <a href={downloadUrl} download={`${downloadTokenID}-hires.zip`} className={`button`}>
                    <h3>
                      Save
                      <DownloadOutlined />
                    </h3>
                  </a>
                ) : fetching ? (
                  <div className="button disabled">
                    <h3>
                      <LoadingOutlined />
                    </h3>
                  </div>
                ) : (
                  <div
                    className={`button`}
                    onClick={async () => {
                      const signature = await userSigner.signMessage("This is my Noumena!");
                      if (signature) downloadToken(signature, downloadTokenID);
                    }}
                  >
                    <h3>
                      Sign
                      <EditOutlined />
                    </h3>
                  </div>
                )}
              </Col>
              {error && (
                <Col span={24}>
                  <span className={`status`}>{error}</span>
                </Col>
              )}
            </Row>
          </Col>
        ) : (
          <Col span={24}>
            <p>
              You'll need to <a onClick={connectWallet}>connect your wallet</a> and sign a message in order to confirm
              you are the owner of the Noumena before you can download.
            </p>
          </Col>
        )}
      </Row>
    </div>
  );
}
