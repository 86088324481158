import { PageHeader, Button, Menu } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-scroll";
import ReactGA from "react-ga";
import { TwitterOutlined } from "@ant-design/icons";
import { ReactComponent as DiscordIcon } from "../../../assets/icons/discord.svg";
import { ReactComponent as OpenseaIcon } from "../../../assets/icons/opensea.svg";
import { ReactComponent as EtherscanIcon } from "../../../assets/icons/etherscan.svg";
import "./mobile-nav.css";

export default function MobileNav(props) {
  return (
    <div className={`mobile-nav ${props.menuOpen && "open"}`}>
      <div className="menu">
        {props.isOpen && (
          <Link
            onClick={() => {
              ReactGA.event({
                category: "nav",
                action: "mint",
              });
              props.setMenuOpen(false);
            }}
            activeClass="active"
            className="item"
            to="minter"
            spy={true}
            smooth={true}
            duration={500}
            offset={-100}
          >
            mint
          </Link>
        )}

        <Link
          onClick={() => {
            ReactGA.event({
              category: "nav",
              action: "download",
            });
            props.setMenuOpen(false);
          }}
          activeClass="active"
          className="item"
          to="download"
          spy={true}
          smooth={true}
          duration={500}
          offset={-100}
        >
          download
        </Link>

        <Link
          onClick={() => {
            ReactGA.event({
              category: "nav",
              action: "how",
            });
            props.setMenuOpen(false);
          }}
          activeClass="active"
          className="item"
          to="how-to"
          spy={true}
          smooth={true}
          duration={500}
          offset={-100}
        >
          how to
        </Link>

        <Link
          onClick={() => {
            ReactGA.event({
              category: "nav",
              action: "benefits",
            });
            props.setMenuOpen(false);
          }}
          activeClass="active"
          className="item"
          to="benefits"
          spy={true}
          smooth={true}
          duration={500}
          offset={-100}
        >
          benefits
        </Link>

        <Link
          onClick={() => {
            ReactGA.event({
              category: "nav",
              action: "what",
            });
            props.setMenuOpen(false);
          }}
          activeClass="active"
          className="item"
          to="what"
          spy={true}
          smooth={true}
          duration={500}
          offset={-100}
        >
          what is it?
        </Link>

        <Link
          onClick={() => {
            ReactGA.event({
              category: "nav",
              action: "next",
            });
            props.setMenuOpen(false);
          }}
          activeClass="active"
          className="item"
          to="next"
          spy={true}
          smooth={true}
          duration={500}
          offset={-100}
        >
          what's next?
        </Link>

        <div className="socials">
          <a className="item" target="_blank" href="https://twitter.com/noumena_nft">
            <TwitterOutlined />
          </a>
          <a className="item" target="_blank" href="https://discord.gg/xUuBRp6A">
            <DiscordIcon style={{ marginTop: "3px" }} />
          </a>
          <a className="item" target="_blank" href="https://opensea.io/collection/noumena-ai-floral">
            <OpenseaIcon style={{ marginTop: "3px" }} />
          </a>
          <a
            className="item"
            target="_blank"
            href="https://etherscan.io/address/0xfa8467b1d5940bef0f7814827b8d548d38794677#contracts"
          >
            <EtherscanIcon style={{ marginTop: "4px" }} />
          </a>
        </div>

        {props.isConnected && (
          <div
            className="item logout-btn"
            onClick={() => {
              ReactGA.event({
                category: "wallet",
                action: "disconnect",
              });
              props.logout();
            }}
          >
            logout {props.address.substr(0, 6)}...
          </div>
        )}

        {!props.isConnected && (
          <div
            className="item connect-btn"
            onClick={() => {
              ReactGA.event({
                category: "wallet",
                action: "connect",
              });
              props.setMenuOpen(false);
              props.connectWallet();
            }}
          >
            connect wallet
          </div>
        )}
      </div>
    </div>
  );
}
